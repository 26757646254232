import React from 'react';
import pageStyle from '../../assets/css/page.module.css';
import style from '../../assets/css/main.module.css';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import HeroPage from '../../components/hero.page';
import Hamburger from '../../components/hamburger';
import { Grid, Row, Col } from 'react-flexbox-grid';

import imgSEO from '../../assets/img/heroes/mobile.jpg';
import SimilarCard from '../../components/similar.card';
import similar1 from '../../assets/img/heroes/thumbnails/website.jpg';
import similar2 from '../../assets/img/heroes/thumbnails/social2.jpg';
import similar3 from '../../assets/img/heroes/thumbnails/newsletter.jpg';
import SEO from '../../components/seo';
import CtaSection from '../../components/cta.section';
import cta from '../../assets/img/illustrations/social_influencer.svg';
import contentPic from '../../assets/img/illustrations/mobile_app.svg';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Applications mobile | Agence digitale | MDS Digital Agency"
      description="MDS Digital Agency est une agence digitale spécialisée dans la création d'applications mobiles."
      keywords="mobile app, ios, android, react native"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgSEO} />

    <article className={pageStyle.article}>
      <h2 className={pageStyle.header_uppercase}>Application mobile</h2>
      <div className={pageStyle.intro_container} style={{ marginTop: '3em', marginBottom: '6em' }}>
        {/* <h4 className={pageStyle.intro}>
          Booster vos ventes et communiquer sur votre entreprise avec des newsletters percutantes.
        </h4> */}
      </div>
      <Grid style={{ marginBottom: '6em', textAlign: 'right' }}>
        <Row>
          <Col md={6} className={pageStyle.flex_v_align}>
            <p>
              Parce que l’utilisation des smartphones ne cesse de croître, proposer une application
              mobile devient indispensable pour élargir son audience. Cette application permet à vos
              utilisateurs d’avoir votre outil en permanence à portée de main et de l’utiliser même
              hors connexion.
            </p>
          </Col>
          <Col style={{ paddingLeft: '50px' }} md={6}>
            <img src={contentPic} alt="" />
          </Col>
        </Row>
      </Grid>

      <CtaSection
        img={cta}
        ctaText="Devis en ligne"
        title="J'ai une idée d'application mobile mais je ne sais pas comment m'y prendre. Pouvez-vous m'aider?"
        subtitle="Bénéficiez de nos experts afin de vous accompagner dans vos projets les plus fous!"
      />
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard text="Site web" url="/services/siteweb/" img={similar1} />
          <SimilarCard text="Réseaux sociaux" url="/services/reseaux-sociaux/" img={similar2} />
          <SimilarCard text="Newsletter" url="/services/newsletter/" img={similar3} />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
